<template>
  <v-sheet class="pa-5" elevation="1">
    <div class="mx-10">
      <v-row no-gutters>
        <v-col cols="12">
          <h2>Next Trip: {{ upcomingTrip.vanCustomId }}</h2>
        </v-col>
        <v-col cols="8" align-self="center">
          <v-row>
            <v-col cols="3" v-for="item in tripView" :key="item.id">
              <v-list-item two-line class="pl-0">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">{{
                    item.title
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.value }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <div id="map" class="mt-5" style="height: 16.5em"></div>
        </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script>
import moment from "moment";
import { Loader } from "@googlemaps/js-api-loader";

const MapsLoader = new Loader({
  apiKey: "AIzaSyCNFSebRQR4JK1qvH-yRGolz07ps57BAyA",
  version: "weekly",
  libraries: ["places"]
});

export default {
  props: {
    upcomingTrip: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    loader: MapsLoader,
    profileTitles: [],
    tripView: [],
    trip: {
      tripId: "SN-CMPR-0001",
      customerName: "Papa Loum",
      tripDate: new Date("2021-02-21").toISOString(),
      tripDuration: "15h00 - 17h00",
      vanId: "SN-VC-001",
      tripDestination: "DSS Airport-Dakar",
      babySeat: "Yes - 2",
      passengers: 6,
      luggages: 7,
    },
  }),


  created() {
    console.log(this.upcomingTrip)
    this.tripView = [
      {
        id: 1,
        title: "Customer Name",
        value: `${this.upcomingTrip.customerFullName}`,
      },
      {
        id: 2,
        title: "Date",
        value: `${this.getDate(this.upcomingTrip.pickUpDateTime)}`,
      },
      {
        id: 3,
        title: "Trip Duration",
        value: `${this.upcomingTrip.estimatedTime}`,
      },
      {
        id: 4,
        title: "Van ID",
        value: `${this.upcomingTrip.vanCustomId}`,
      },
      {
        id: 5,
        title: "Trip Destination",
        value: `${this.upcomingTrip.dropOffLocation.name}`,
      },
      {
        id: 6,
        title: "Baby Seat",
        value: `${this.upcomingTrip.babySeats}`,
      },
      {
        id: 7,
        title: "Passenger(s)",
        value: `${this.upcomingTrip.passengers}`,
      },
      {
        id: 8,
        title: "Luggage(s)",
        value: `${this.upcomingTrip.luggages}`,
      },
    ];

    this.initMap();
  },

  methods: {
    getDate(date) {
      return moment(date)
        .locale("fr")
        .format("dddd D MMMM YYYY");
    },

    initMap() {

      this.loader.load().then(() => {
        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer();

        this.map = new google.maps.Map(document.getElementById("map"), {
          center: { lat: -34.397, lng: 150.644 },
          zoom: 7,
          disableDefaultUI: true,
        });

        directionsService
          .route({
            origin: new google.maps.LatLng(`${this.upcomingTrip.pickUpLocation.lat}`, `${this.upcomingTrip.pickUpLocation.lng}`),
            destination: new google.maps.LatLng(`${this.upcomingTrip.dropOffLocation.lat}`, `${this.upcomingTrip.dropOffLocation.lng}`),
            travelMode: google.maps.TravelMode.DRIVING,
          })
          .then((response) => {
            directionsRenderer.setDirections(response);
          });

        directionsRenderer.setMap(this.map);
      });
    },
  },
};
</script>

<style></style>
